/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

#header {
  text-align:center;

}
.img_header {
  max-height:150px;
  max-width: 70%;
}
ion-title,ion-content {
  color:var(--ion-text-color);
  --ion-color:var(--ion-text-color);
}
ion-app.cameraView,
ion-app.cameraView ion-content,
ion-app.cameraView .nav-decor,
ion-app.cameraView ion-page,
ion-app.cameraView ion-modal, {
  background: transparent none !important;
  --background :transparent none !important;
  --ion-background-color: none !important; // this is important
}
ion-app.cameraView ion-content {
  opacity:0;
}
.text-center{
  text-align:center;
}
